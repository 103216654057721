.case_main_title {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 31px;
  color: #ffffff;

  margin-top: 17px;
}
