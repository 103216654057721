.container {
  display: inline-block;
  margin: 16px 30px;
}

.span {
  height: 10px;
  width: 10px;
  border-radius: 50%;
  display: inline-block;
  margin-right: 10px;
}
