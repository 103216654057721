.table {
  position: relative;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 17px;
    height: 17px;
    background: #272e48;
  }

  :global {
    .ant-table-bordered .ant-table-thead > tr > th,
    .ant-table-bordered .ant-table-tbody > tr > td {
      border-right: none !important;
      border-left: none !important;
    }

    .ant-table-bordered .ant-table-header > table,
    .ant-table-bordered .ant-table-body > table,
    .ant-table-bordered .ant-table-fixed-left table,
    .ant-table-bordered .ant-table-fixed-right table {
      border: none !important;
    }

    .ant-table-small.ant-table-bordered .ant-table-fixed-right {
      border: none !important;
    }

    .ant-table-small.ant-table-bordered .ant-table-content {
      border: none !important;
    }

    .ant-table-small > .ant-table-content .ant-table-header {
      border-radius: 0px !important;
    }
  }
}

