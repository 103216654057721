.select {
  width: 100%;
}

.checkbox {
  color: #fff;
}

.row {
  margin-bottom: 10px;
  align-items: end;
}

.title {
  margin: 0;
}

.container {
  display: flex;
  gap: 16px;
  align-items: center;
  padding-top: 6px;
}

.picker {
  margin-right: auto;
  margin-bottom: 6px;
  width: 100% !important;
}
