.tag {
  max-width: 450px;
  border: 1px solid white;
  color: white;
  position: relative;
  left: 50%;
  transform: translate(-50%, 8px);
  z-index: 999;
  position: relative;
  padding-right: 24px;

  &__title {
    white-space: pre-line;
  }

  :global {
    .anticon-close {
      position: absolute;
      top: 50%;
      right: 4px;
      transform: translate(0, -50%);
    }
  }
}
