.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #2f2f39;
  padding: 0 24px;

  &__notifications {
    width: 400px;
    height: 540px;
    overflow: auto;
  }
  .logo {
    display: flex;
    width: 100%;
    align-items: center;
    gap: 5px;
    font-size: 14px;
  }
}
