.title {
  text-align: center;
  min-height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #2F2F39;
}
.graph_wrapper {
  height: 320px;
  background: #40404D;
  border: 1px solid #40404D;
  margin-bottom: 20px;
}
