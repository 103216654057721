.wrap {
  background-color: #1e1f26;
  color: #fff;
}

.titleBig {
  font-weight: bold;
  font-size: 18px;
  line-height: 21px;
}

.title {
  margin-bottom: 0;
  font-size: 14px;
  line-height: 1.5;
  font-weight: bold;
}

.stat {
  margin-bottom: 0;
  font-size: 36px;
  font-weight: bold;
  line-height: 1;
}

.totalCalls {
  padding: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  background-color: #2f2f39;
  border-radius: 8px;
  position: relative;

  span {
    position: absolute;
    margin-top: 7px;
    width: 2px;
    background-color: #4b5765;
    height: 35%;
    left: 18%;
  }
}

.icon {
  padding: 10px 12px;
  border: 3px solid #5a697a;
  border-radius: 8px;
  display: inline-block;

  svg {
    font-size: 26px;
    position: relative;
    top: 2px;
  }
}

.content {
  margin-left: 2%;
}

.stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;

  .title {
    font-size: 20px;
    text-transform: uppercase;
    margin: 20px 0;
  }

  .smallStats {
    position: relative;
    display: flex;
    justify-content: space-around;

    span {
      position: absolute;
      margin-top: 15px;
      width: 2px;
      background-color: #4b5765;
      height: 60%;
    }

    p {
      font-weight: bold;
      margin-bottom: 0;
    }

    h4 {
      margin-top: 5px;
      font-size: 22px;
      font-weight: bold;
    }
  }
}

.legends {
  margin-top: 30px;
  width: 50%;
  margin-bottom: 20px;
}

.legendItem {
  background-color: #262730;
  padding: 8px 15px;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;

  span {
    width: 12px;
    height: 12px;
    position: relative;
    top: 5px;
    border-radius: 50%;
  }

  h4 {
    margin-left: 10px;
    width: 80%;
    font-size: 15px;
    font-weight: normal;
    margin-bottom: 0;
  }

  h5 {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: bold;
  }
}

.chartsWrap {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.pieWrap {
  background-color: #2f2f39;
  border-radius: 8px;
  padding: 20px;
  flex: 0 1 calc(60% - 10px);
  display: flex;
  flex-wrap: wrap;
  h2 {
    flex: 0 0 100%;
  }
}

.barWrap {
  background-color: #2f2f39;
  border-radius: 8px;
  padding: 20px;
  flex: 0 1 calc(40% - 13px);
}

.statsItem {
  background-color: #2f2f39;
  text-align: center;
  border-radius: 8px;
  flex: 0 1 calc(20% - 20px);
  padding: 20px;
}

.cameraStats {
  display: flex;
  justify-content: space-between;
  padding-bottom: 20px;
}

.cameraItem {
  padding: 20px;
  border-radius: 8px;
  background-color: #2f2f39;
  flex: 1 1 auto;
  margin-right: 20px;
  &:last-child {
    margin-right: 0;
  }
}

.cameraItemCategories {
  margin-top: 15px;
}

.cameraItemWrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.catItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #262730;
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    font-size: 21px;
    width: 21px;
  }

  p {
    margin-bottom: 0;
    font-size: 13px;
    line-height: 1.3;
    text-align: left;
    margin-left: 10px;
    width: 70%;
  }

  span {
    font-size: 14px;
    font-weight: bold;
  }
}
