$color-bg: #19375a;
$color-main: #234870;
$color-secondary: #1f5d8e;
$color-active: #1890ff;
@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:ital,wght@0,400;0,500;0,600;0,700;1,400&display=swap');

#root,
.App,
.page-layout {
  height: 100%;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  color: #fff;
  background: #1e1f26;
}

::-webkit-scrollbar {
  background-color: transparent;
  width: 8px;
  height: 6px;
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
  background-color: #c4c4c44d;
  border-radius: 16px;
  border: 2px solid transparent;
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {
  display: none;
}

main {
  width: 100%;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #fff;
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 150px;
  height: 150px;
  margin: -75px 0 0 -75px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.ant-table-thead
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-tbody
  > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
  > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
  background: #40566f !important;
}

#root .dark_table {
  .ant-table-header {
    background-color: #1f2739;
  }

  .ant-table-thead > tr:first-child > th:first-child {
    border-top-left-radius: 0 !important;
  }
  .ant-table-thead > tr:first-child > th:last-child {
    border-top-right-radius: 0 !important;
  }

  .ant-table-body {
    background: #272e48 /*#3a3a3a*/ !important;
    margin: 0 !important;
  }

  .ant-table-thead > tr > th {
    background-color: #1f2739;
    color: #fff;
  }

  .ant-table-tbody > tr:nth-child(2n) {
    background-color: #2c3446; /*#343434;*/
  }
  .ant-table-tbody > tr:nth-child(2n + 1) {
    background-color: #323c50; /*#343434;*/
  }

  .ant-table-tbody > tr > td {
    vertical-align: top;
    color: #fff;
    font-size: 13px;
    line-height: 1;
    // text-align: center;
    padding: 20px 0px !important;
    border-bottom-color: #000;
  }
  .ant-table-tbody tr:hover,
  .ant-table-tbody tr:hover td,
  .ant-table-tbody
    > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected)
    > td {
    background: #40566f !important;
  }

  .ant-table-body::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: #f5f5f5;
  }
  .ant-table-body::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #f5f5f5;
  }
  .ant-table-body::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: #f5f5f5;
  }
  .ant-table-body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #cdcdcd;
  }

  .ant-pagination-item-link {
    color: #fff;
    background: #19375a;
  }
  .ant-pagination-item {
    a {
      color: #fff;
    }
    background: #19375a;
  }
  .ant-pagination-item-ellipsis {
    color: #fff;
  }
}

.page-title {
  font-size: 20px;
  line-height: 1;
  margin-bottom: 20px;
}

.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}

.ant-card {
  color: #98a4b9;
  border-radius: 10px;
}
.ant-card-head {
  color: #fff;
  background: #323c48;
}
.ant-card-body {
  background: #323c48;
}
.ant-card-bordered {
  border: none;
}

main,
.content {
  height: calc(100vh - 100px);
}

header {
  z-index: 12;
  position: relative;
  height: 50px;
  background-color: #343740;
  .logo {
    display: flex;
    align-items: center;
    width: 200px;
    img {
      display: block;
      margin: 5px;
    }
    h3 {
      font-size: 1em;
      line-height: 1.5;
      margin: 4px 0 0;
      color: #fff;
    }
    & > div {
      margin-left: 5px;
    }
  }

  a {
    color: #fff;
    font-size: 12px;
  }
}

header .logo:hover + nav {
  display: flex;
}

main {
  display: flex;
}

nav {
  display: none;
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background-color: rgba(33, 36, 44, 0.9);
  // width: 50px;
  box-shadow: 2px 0px 13px -6px #848484;
  transition: width 0.1s linear;
  // height: 44vh;
  z-index: 12;
  padding: 24px 16px;
  ul {
    flex: 0 0 25%;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  li.active {
    // background-color: #43b4f5;
    .white {
      display: block;
    }
    .dark {
      display: none;
    }
  }
  li a {
    color: #fff;
    display: flex;
    align-items: center;
    // border-bottom: 2px solid rgb(158, 203, 209);
    padding: 5px;
    height: 40px;
    &:hover {
      text-decoration: underline;
      // background-color: #43b4f5;
      .white {
        display: block;
      }
      .dark {
        display: none;
      }
    }
    img {
      padding: 7px;
      width: 35px;
      margin-right: 5px;
    }
    .white {
      display: none;
    }
    h3 {
      margin-bottom: 0;
      // opacity: 0;
      flex: 1;
      color: #fff;
      white-space: nowrap;
      text-transform: uppercase;
      font-weight: 500;
      font-size: 13px;
      margin-left: 10px;
      // visibility: hidden;
    }
  }
  &:hover {
    display: flex;
    h3 {
      opacity: 1;
      // visibility: visible;
    }
  }
}

.content {
  width: 100%;
}

.p-10 {
  padding: 10px;
}

.h-100 {
  height: 100%;
}

.mb-10 {
  margin-bottom: 10px;
}

/* Forecast page */
.fc-graph {
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  overflow: auto;
}

.fc-table {
  display: flex;
  flex-direction: column;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 70%;
  flex: 0 0 70%;
  padding: 0 10px 0 0;
  overflow: auto;
}

.fc-controls {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 5px;
  background-color: #2F2F39;
}
.fc-controls__heading {
  /* font-size: 16px; */
  font-size: 14px;
  font-weight: 500;
  padding: 10px 0;
  margin-bottom: 0;
  border: 1px solid #40404D;
  border-bottom: none;
}

.fc-controls__list {
  flex: 1;
  margin-bottom: 14px;
  overflow: auto;
}

.fc-controls__list .fc-control {
  margin: 0 0 20px;
  background: #2F2F39;
}
.rangeslider {
  margin: 12px 0 !important;
}
.rangeslider-horizontal {
  height: 4px !important;
}
.rangeslider-horizontal .rangeslider__fill {
  background-color: #32cd66 !important;
}  
.fc-control__title {
  color: #fff;
  /* font-size: 10px; */
  font-size: 0.7vw;
  text-transform: uppercase;
  margin: 0 0 10px;
} 
.fc-controls__list .fc-control .fc-control__slider {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0;
}
.fc-control__arrows {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  color: #fff;
  /* width: 100px; */
  width: 6.5vw;
  padding: 4px 10px;
}
.fc-control__arrow {
  color: #32cd66;
  font-size: 1vw;
  cursor: pointer;
  /* padding: 0 4px; */
  padding: 0 0.2vw;
}
.fc-control__value {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  /* font-size: 14px; */
  font-size: 1vw;
}

/* fc */
.slider_wrap {
  display: flex;
  text-align: center;

  .fc-control__slider {
    display: flex;
    flex: 1;
    padding: 0 10px;
  }
  .fc-control__arrows {
    width: 100px;
    text-align: center;
    display: flex;
    align-items: center;
  }
}

.rangeslider-horizontal {
  width: 100%;
}

.fc-controls {
  height: 66vh;
  border: 1px solid #40404D;
  overflow-x: auto;
}

.pull-right {
  margin-left: 10px;
}

.fc-control__title {
  font-size: 12px;
  padding-top: 10px;
}

.fc-control {
  background-color: #09192b82;
  padding: 2px 10px;
  margin-bottom: 5px;
  border-radius: 5px;
  margin-right: 3px;
}

.fc-controls__heading {
  text-align: center;
}

.tableStyled {
  thead tr {
    color: #2a323c;
    font-weight: normal;
    background: #2a323c;
  }

  th {
    color: #fff;
    font-weight: normal;
    background: #2a323c;
  }

  td:hover {
    background-color: #fff842;
    color: #403e10;
    font-weight: bold;

    box-shadow: #7f7c21 -1px 1px, #7f7c21 -2px 2px, #7f7c21 -3px 3px, #7f7c21 -4px 4px,
      #7f7c21 -5px 5px, #7f7c21 -6px 6px;
    transform: translate3d(6px, -6px, 0);
    line-height: 24px;
    transition-delay: 0s;
    transition-duration: 0.4s;
    transition-property: all;
    transition-timing-function: line;
    font-size: 14px;
  }

  td,
  th {
    padding-bottom: 0.75%;
    padding-top: 0.75%;
    padding-left: 0.75%;
  }

  /*
  th:hover {
    background-color: #464A52;
 -webkit-box-shadow: 0 6px 6px -6px #0E1119;
      -moz-box-shadow: 0 6px 6px -6px #0E1119;
           box-shadow: 0 6px 6px -6px #0E1119;
 }*/

  tbody tr td {
    min-width: 120px;
  }
  tbody tr:nth-child(odd) {
    background: #2a323c;
  }
  tbody tr:nth-child(even) {
    background: #2a323c;
  }
  .clickable {
    td:first-child {
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.indicators {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  .indicator_item {
    margin: 1px;
    flex: 1 0 41%;
    padding: 10px 5px;
    background: rgb(50, 60, 72);
  }
  .table_item {
    margin: 1px;
    flex: 1 0 41%;
    background: #314f71;
    padding: 10px 5px;
  }
  h4 {
    height: 55px;
  }
}

.documents-list {
  margin-top: 21px;
  padding-left: 1px;
  div {
    padding: 10px;
    background-color: $color-secondary;
    cursor: pointer;
    &:hover {
      background-color: $color-active;
    }
  }
  .active {
    background-color: $color-active;
  }
}

.notification {
  color: #fff;
  border-radius: 16px;
  margin-bottom: 20px;

  .location {
    line-height: 1;
    margin-bottom: 2px;
  }

  .date {
    font-size: 11px;
    line-height: 1;
    color: #fff;
    opacity: 0.7;
    margin-bottom: 6px;
  }

  .title {
    font-size: 16px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 6px;
  }
  .text {
    font-size: 13px;
    line-height: 1;
  }
}
.call-phone {
  padding: 10px;
  border: solid #bbc2c9;
  width: 70px;
  height: 60px;
  //padding-top: 25px;
  /* margin-top: 20px; */
  border-radius: 10px;
  margin-left: 25px;
}
.edds-fonts {
  margin-left: 85px;
  margin-top: 25px;
}

.edds-icons {
  padding: 10px;
  border: solid #bbc2c9;
  width: 70px;
  height: 60px;
  //padding-top: 25px;
  /* margin-top: 20px; */
  border-radius: 10px;
  margin-left: 85px;
  margin-top: 25px;
  //align-items: center;
}

.container1 {
  margin-top: -50px;
  margin-left: 110px;
}

//Ant table style
.ant-table-small {
  border: 0;
  border-radius: 0;
}
.ant-table-small > .ant-table-content .ant-table-header,
.ant-table-thead {
  background: #1f2739;
}
.ant-table-thead > tr > th {
  vertical-align: top;
  color: #fff;
  font-size: 12px;
  // text-align: center;
  line-height: 1;
  // padding: 12px 0 !important;
  border-bottom-color: rgb(46, 46, 46);
  background: #1f2739;

  .ant-table-column-title {
    font-size: 14px;
  }
}
.roadRepair .ant-table-thead > tr > th {
  color: black !important;
}

.ant-table-thead > tr:first-child > th:first-child {
  border-top-left-radius: 0 !important;
}
.ant-table-thead > tr:first-child > th:last-child {
  border-top-right-radius: 0 !important;
}

.ant-table-body {
  background: #272e48 /*#3a3a3a*/ !important;
  margin: 0 !important;
}
.ant-table-tbody > tr {
  cursor: pointer;
}

.ant-table-tbody > tr:nth-child(2n) {
  background-color: #2c3446; /*#343434;*/
}
.ant-table-tbody > tr:nth-child(2n + 1) {
  background-color: #323c50; /*#343434;*/
}
.ant-table-tbody > tr > td {
  vertical-align: top;
  color: #fff;
  font-size: 13px;
  line-height: 1;
  // text-align: center;
  padding: 20px 8px !important;
  border-bottom-color: #000;
}
.ant-table-tbody > tr:hover,
.ant-table-tbody > tr:hover > td {
  background: #40566f !important;
}

.ant-table-wrapper {
  .ant-table-pagination {
    .ant-pagination-item,
    .ant-pagination-item-link {
      .ant-pagination-item-ellipsis,
      .ant-select-selection,
      svg,
      a {
        color: #fff;
        background: transparent;
        &:hover {
          color: #1890ff !important;
        }
      }
    }
    .ant-pagination-item-active {
      background: transparent !important;
      a {
        color: #1890ff !important;
      }
    }
    .ant-pagination-options {
      .ant-select-selection,
      svg,
      a {
        color: #fff;
        background: transparent;
      }
    }
  }
}

.ant-modal-header {
  span button {
    margin-right: 20px;
  }
}
.ant-modal-header {
  background: #323c48;
  padding-bottom: 0;
  border-bottom: 0;
}
.ant-modal-title {
  color: #fff;
}
.ant-modal-content {
  background: #323c48;
}
.ant-modal-close-x {
  color: #fff;
}
.appeals-citizen {
  .ant-statistic-title {
    font-size: 13px;
    position: relative;
    top: -9px;
    height: 25px;
    line-height: 1.3;
  }
}
.ant-statistic-title,
.ant-statistic-content {
  color: #98a4b9;
}
.value.small-text .ant-statistic-content {
  font-size: 16px;
  font-weight: bold;
}

.light-blue .ant-statistic-content {
  color: #01a4b4;
}

.light-green .ant-statistic-content {
  color: rgba(154, 186, 47, 1);
}

.appeal-numbers .ant-card-body {
  background-color: #2a323c;
  padding: 15px;
}
.appeal-numbers {
  margin-bottom: 7.5px;
}

.ant-popover-inner,
.ant-popover-arrow {
  background: #2f2f39 !important;
  color: #505156 !important;
  border: 1px solid #505156;
  border-top-color: #505156 !important;
  border-left-color: #505156 !important;
}

.ant-popover-title {
  color: #fff !important;
  border-bottom: none !important;
  font-family: 'IBM Plex Sans';
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 16px;
  padding-top: 16px;
}

.statistic_card {
  border: 1px solid #40404d;
  border-radius: 4px;
  padding: 18px;
  margin-bottom: 24px;
  text-align: center;

  font-weight: 500;
  font-size: 14px;

  .ant-statistic-content {
    display: flex;
    flex-direction: column;
  }

  .ant-statistic-content-prefix {
    margin: 15px 0;
  }
}

.forecast-chart-wrapper {
  margin-top: 10px;
  border: 1px solid #40404D;
  border-radius: 4px;
  .forecast-chart {
    padding: 20px 0;
    background: #2F2F39;
  }
  .forecast-chart-title {
    padding: 10px 0 5px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
  }
}

.forecast-parameters {
  height: 66vh;
  margin-top: 134px;
  border-bottom: none;
  border-radius: 4px;
}

.tab-header-title {
  padding-bottom: 16px;
  margin-right: 30px;
  font-size: 16px;
  font-weight: 500;
  color: #fff;
}

.tab-header-title-active {
  color: #2ba6ff;
  border-bottom: 2px solid #2ba6ff;
}

.forecast-tab-header {
  padding: 16px 0;
  border-bottom: 1px solid #40404D;

  .forecast-tab-header-title {
    @extend .tab-header-title;
  }

  .forecast-tab-header-title-active {
    @extend .tab-header-title-active;
  }
}

.ant-tabs-tab {
  font-size: 16px;
}

.ant-tabs-tab .ant-tabs-tab-active {
  color: #2ba6ff;
}