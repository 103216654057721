.date_picker {
  &_wrapper {
    max-width: 400px;
  }

  &_title {
    font-family: 'IBM Plex Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 22px;
    margin-bottom: 8px;
  }

  &_component {
    width: 100%;

    :global {
      .ant-calendar-picker-input {
        min-height: 34px !important;
        padding-top: 6px;
        padding-bottom: 7px;
        background-color: #2f2f39;
        border: 1px solid #40404d;
        border-radius: 4px;
        color: #ffffff;
        vertical-align: middle;

        .ant-calendar-range-picker-separator {
          color: #ffffff;
          vertical-align: initial;
        }
      }

      svg {
        path {
          fill: #fff;
        }
      }

      .ant-calendar-picker-clear {
        background-color: #2f2f39;
      }
    }
  }
}
