.container {
  padding: 16px;
  background: #2F2F39;
  border: 1px solid #40404D;
}

.col {
  display: flex !important;
  flex-direction: column;
  justify-content: space-between;
}

.title {
  text-align: center;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.6);
}

.subtitle {
  font-size: 20px;
  text-align: center;
}
