.item {
  :global(.ant-select-selection) {
      background-color: #2f2f39;
      border: 1px solid #40404d;
      color: #ffffff;
  }
  :global(.ant-select-arrow) {
      svg {
        path {
          fill: #fff;
        }
      }
    }
}
